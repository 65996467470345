<script>
import Hero from "./components/sections/hero/Hero.vue";
import Form from "./components/sections/form/Form.vue";

export default {
  components: {
    Hero,
    Form,
  },
};
</script>

<template>
  <div>
    <Hero />
    <Form />
  </div>
</template>

<style scoped></style>
