<template>
  <div class="section">
    <form @submit.prevent="sendForm">
      <div class="form-group row">
        <div class="col-12 col-md-6 firstRowLeft">
          <label class="" for="nameAndSurname"
            >{{ $t("complaint form.name") }}*</label
          >
          <input
            class="input"
            type="text"
            id="nameAndSurname"
            v-model="nameAndSurname"
            :placeholder="$t('complaint form.placeholder.name')"
          />
        </div>
        <div class="col-12 col-md-6 firstRowRight">
          <label class="" for="tradingAccountNumber"
            >{{ $t("complaint form.trading-account") }}*</label
          >

          <input
            class="input"
            type="text"
            id="tradingAccountNumber"
            v-model="tradingAccountNumber"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="" for="email">{{ $t("complaint form.email") }}*</label>
        <input
          class="input"
          type="email"
          id="email"
          v-model="email"
          :placeholder="$t('complaint form.placeholder.email')"
        />
      </div>

      <div class="form-group">
        <label class="">{{ $t("complaint form.mobile") }}*</label>
        <div>
          <MazPhoneNumberInput
            type="tel"
            v-model="phoneNumber"
            @update="results = $event"
            class="mb-32px"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="" for="address"
          >{{ $t("complaint form.address") }}*</label
        >
        <input
          class="input"
          type="text"
          id="address"
          v-model="address"
          :placeholder="$t('complaint form.placeholder.address')"
        />
      </div>

      <div class="form-group row">
        <div class="col-12 col-md-6 firstRowLeft">
          <label class="" for="city">{{ $t("complaint form.city") }}*</label>
          <input
            class="input"
            type="text"
            id="city"
            v-model="city"
            :placeholder="$t('complaint form.placeholder.city')"
          />
        </div>

        <div class="col-12 col-md-6 firstRowRight">
          <label class="" for="postalCode"
            >{{ $t("complaint form.postal code") }}*</label
          >
          <input
            class="input"
            type="text"
            id="postalCode"
            v-model="postalCode"
            :placeholder="$t('complaint form.placeholder.postal code')"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="" for="country"
          >{{ $t("complaint form.country") }}*</label
        >
        <div class="select-wrapper">
          <select class="input" id="country" color="primary" v-model="country">
            <option
              v-for="country in countries"
              :key="country.value"
              :value="country.value"
            >
              {{ country.label }}
            </option>
          </select>
        </div>
      </div>

      <!-- <div class="form-group">
        <label class="" for="typesOfComplain"
          >{{ $t("complaint form.type of complaint.title") }}*</label
        >
        <div class="select-wrapper">
          <select
            class="input"
            id="typesOfComplain"
            color="primary"
            v-model="typeOfComplaint"
          >
            <option
              v-for="type in $t('complaint form.type of complaint.type')"
              :key="type"
              :value="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div> -->

      <div class="form-group">
        <div class="">
          <label class="" for="subject"
            >{{ $t("complaint form.subject") }}*</label
          >
          <input
            class="input"
            type="text"
            id="subject"
            v-model="subject"
            :placeholder="$t('complaint form.placeholder.subject')"
          />
        </div>

        <!-- <div class="col-12 col-md-6 firstRowRight">
          <label class="" for="disputedAmount"
            >{{ $t("complaint form.disputed amount") }}*</label
          >
          <input
            class="input"
            type="text"
            id="disputedAmount"
            v-model="disputedAmount"
            :placeholder="$t('complaint form.placeholder.disputed amount')"
          />
        </div> -->
      </div>

      <div class="form-group d-flex flex-column">
        <label class="" for="descriptionOfComplaint">{{
          $t("complaint form.description")
        }}</label>
        <textarea
          id="descriptionOfComplaint"
          class="input"
          style="height: 156px !important"
          cols="30"
          rows="10"
          v-model="descriptionOfComplaint"
        ></textarea>
      </div>

      <div class="form-group" style="display: inline-block">
        <label
          class="button-small text-grey-primary-main d-flex align-items-center"
          for="attachment"
        >
          <img src="../../svg/upload.svg" style="margin-right: 8px" />
          <span style="text-decoration: underline">
            {{ $t("complaint form.upload") }}
          </span>
        </label>
        <input type="file" id="attachment" />
      </div>
      <div class="d-flex justify-content-center" style="margin: 38px auto 71px">
        <b-button
          type="submit"
          variant="primary"
          class="round-btn-19-20"
          style="width: 200px !important"
          ><p class="button-large" style="height: 18px !important">
            {{ $t("complaint form.send") }}
          </p>
        </b-button>
      </div>
    </form>
  </div>
</template>
<script>
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { countriess } from "../../../../../FLAGS_TEST/countries";
// import RightArrow from "../../svg/RightArrow.vue";
import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
export default {
  components: {
    MazPhoneNumberInput,
  },
  methods: {
    async sendForm() {
      try {
        const res = await this.$axios.post(`supports/contact_us`, {
          tradingAccountNumberFake: this.tradingAccountNumberFake,
          nameAndSurname: this.nameAndSurname,
          email: this.email,
          tradingAccountNumber: this.tradingAccountNumber,
          mobileNumber: this.mobileNumber,
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          country: this.country,
          typeOfComplaint: this.typeOfComplaint,
          subject: this.subject,
          disputedAmount: this.disputedAmount,
          descriptionOfComplaint: this.descriptionOfComplaint,
          options: this.options,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  // components: { RightArrow },
  computed: {
    countries() {
      const list = countries.getNames("en", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
    mobileNumber() {
      return this.phoneNumber;
    },
  },

  data() {
    return {
      tradingAccountNumberFake: "",
      nameAndSurname: null,
      email: "",
      tradingAccountNumber: null,
      mobilePrefix: "",
      mobile: "",
      address: "",
      city: "",
      postalCode: "",
      country: null,
      typeOfComplaint: null,
      subject: "",
      disputedAmount: "",
      descriptionOfComplaint: "",
      options: "",
      phoneNumber: null,
      results: null,
    };
  },

  created() {
    this.options = countriess.map((c) => ({
      ...c,
      imageFile: `${c.name.toLowerCase()}.png`,
    }));
  },
  filters: {
    url(value) {
      return require("@/FLAGS_TEST/" + value);
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 668px;
  margin: auto;
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-top: 78px !important;
  }
}

.title {
  text-align: center;
}

label {
  margin-bottom: 12px !important;
}

label[for="attachment"] {
  cursor: pointer;
  margin: 0 !important;
  /* width: 100% !important; */
  /* Style as you please, it will become the visible UI component. */
}

#attachment {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.firstRowLeft {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
  @media (min-width: 768px) {
    padding: 0 4px 0 0 !important;
  }
}
.firstRowRight {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
  @media (min-width: 768px) {
    padding: 0 0 0 4px !important;
  }
}
.mb-32px {
  margin-bottom: 32px;
}
::v-deep .maz-select__options-list__items {
  overflow-y: scroll;
  border-bottom: 16px solid #ffffff;
  border-right: 9px solid #ffffff;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #07b53b;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #9ce1b1;
    width: 5px !important;
  }
}
</style>
