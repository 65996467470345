var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "310",
      "height": "204",
      "viewBox": "0 0 310 204",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "76.9837",
      "cy": "68.554",
      "r": "3.81125",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.5245"
    }
  }), _c('circle', {
    attrs: {
      "cx": "4.57351",
      "cy": "117.339",
      "r": "3.81125",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.5245"
    }
  }), _c('circle', {
    attrs: {
      "cx": "279.745",
      "cy": "82.2727",
      "r": "3.81125",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.5245"
    }
  }), _c('circle', {
    attrs: {
      "cx": "246.206",
      "cy": "168.409",
      "r": "3.81125",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.5245"
    }
  }), _c('path', {
    attrs: {
      "d": "M41.0586 137.707L46.718 143.366",
      "stroke": "#07B53B",
      "stroke-width": "1.5245",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.7227 137.707L41.0632 143.366",
      "stroke": "#07B53B",
      "stroke-width": "1.5245",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M2.94141 185.719L8.60083 191.378",
      "stroke": "#07B53B",
      "stroke-width": "1.5245",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.60547 185.719L2.94604 191.378",
      "stroke": "#07B53B",
      "stroke-width": "1.5245",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "89.5763",
      "height": "78.0962",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 225.523 68.0312)",
      "stroke": "#ED6400",
      "stroke-width": "3.87823",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "109.482",
      "height": "19.524",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 194.059 139.793)",
      "stroke": "#ED6400",
      "stroke-width": "3.87823",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "109.482",
      "height": "19.524",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 244.25 56.1562)",
      "stroke": "#ED6400",
      "stroke-width": "3.87823",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "84.5999",
      "height": "19.524",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 284.375 134.199)",
      "stroke": "#ED6400",
      "stroke-width": "3.87823",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "90.4231",
      "height": "19.3303",
      "transform": "matrix(-1 0 0 1 178.004 163.328)",
      "stroke": "#ED6400",
      "stroke-width": "3.87823",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "120.564",
      "height": "19.3303",
      "transform": "matrix(-1 0 0 1 193.078 182.621)",
      "stroke": "#ED6400",
      "stroke-width": "3.87823",
      "stroke-linecap": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }