var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v(_vm._s(_vm.$t("complaint form.hero.title")))])]), _c('ComplaintFormHero', {
    staticClass: "w-100 d-none d-xl-block"
  }), _c('TabletHero', {
    staticClass: "w-100 d-none d-md-block d-xl-none tablet"
  }), _c('MobileHero', {
    staticClass: "w-100 d-md-none",
    staticStyle: {
      "margin-bottom": "66px"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }