<template>
  <div class="hero">
    <div class="title">
      <h1>{{ $t("complaint form.hero.title") }}</h1>
    </div>
    <ComplaintFormHero class="w-100 d-none d-xl-block" />
    <TabletHero class="w-100 d-none d-md-block d-xl-none tablet" />
    <MobileHero class="w-100 d-md-none" style="margin-bottom: 66px" />
  </div>
</template>
<script>
import ComplaintFormHero from "../../svg/ComplaintFormHero.vue";
import TabletHero from "../../svg/TabletHero.vue";
import MobileHero from "../../svg/MobileHero.vue";
export default {
  components: { ComplaintFormHero, TabletHero, MobileHero },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .title {
    position: static;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .title {
    position: absolute;
    width: 50%;
    top: 40%;
    left: 24%;
  }
  h1 {
    max-width: 393px !important;
    font-weight: 500 !important;
    font-size: 50px !important;
    margin: auto !important;
  }
}
@media (min-width: 1200px) {
  .title {
    position: absolute;
    width: 50%;
    top: 40%;
    left: 26%;
  }
}

h1 {
  /* or 64px */

  text-align: center;

  /* Light / Black */

  color: #373f41;
  @media (max-width: 767px) {
    margin: 37px auto 57px auto !important;
    max-width: 278px !important;
  }
}
</style>
