var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1024",
      "height": "234",
      "viewBox": "0 0 1024 234",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "r": "4.36544",
      "transform": "matrix(-1 0 0 1 266.293 78.0862)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('circle', {
    attrs: {
      "r": "4.36544",
      "transform": "matrix(-1 0 0 1 317.238 93.2385)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('circle', {
    attrs: {
      "r": "4.36544",
      "transform": "matrix(-1 0 0 1 34.0505 93.7971)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('circle', {
    attrs: {
      "r": "4.36544",
      "transform": "matrix(-1 0 0 1 72.4646 192.457)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('path', {
    attrs: {
      "d": "M312.723 182.242L306.24 188.725",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M306.238 182.242L312.721 188.725",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M351.102 212.285L344.619 218.768",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M344.617 212.285L351.1 218.768",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "102.601",
      "height": "89.452",
      "transform": "matrix(0.874212 -0.485544 0.514597 0.857432 96.1562 77.4883)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "125.402",
      "height": "22.363",
      "transform": "matrix(0.874212 -0.485544 0.514597 0.857432 132.195 159.684)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "125.402",
      "height": "22.363",
      "transform": "matrix(0.874212 -0.485544 0.514597 0.857432 74.707 63.8867)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "96.9014",
      "height": "22.363",
      "transform": "matrix(0.874212 -0.485544 0.514597 0.857432 28.7461 153.277)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "150.578",
      "y": "186.641",
      "width": "103.571",
      "height": "22.1411",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "133.312",
      "y": "208.738",
      "width": "138.095",
      "height": "22.1411",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "777.18",
      "cy": "78.0862",
      "r": "4.36544",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('circle', {
    attrs: {
      "cx": "739.239",
      "cy": "98.2385",
      "r": "4.36544",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('circle', {
    attrs: {
      "cx": "1009.42",
      "cy": "93.7971",
      "r": "4.36544",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('circle', {
    attrs: {
      "cx": "971.008",
      "cy": "192.457",
      "r": "4.36544",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.74618"
    }
  }), _c('path', {
    attrs: {
      "d": "M736.027 157.289L742.51 163.771",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M742.512 157.289L736.029 163.771",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M692.371 212.285L698.853 218.768",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M698.855 212.285L692.373 218.768",
      "stroke": "#07B53B",
      "stroke-width": "1.74618",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "102.601",
      "height": "89.452",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 931.25 77.4883)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "125.402",
      "height": "22.363",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 895.211 159.684)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "125.402",
      "height": "22.363",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 952.699 63.8867)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "96.9014",
      "height": "22.363",
      "transform": "matrix(-0.874212 -0.485544 -0.514597 0.857432 998.66 153.277)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "103.571",
      "height": "22.1411",
      "transform": "matrix(-1 0 0 1 876.828 186.641)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "width": "138.095",
      "height": "22.1411",
      "transform": "matrix(-1 0 0 1 894.094 208.738)",
      "stroke": "#ED6400",
      "stroke-width": "4.44215",
      "stroke-linecap": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }