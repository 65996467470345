var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.sendForm.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-12 col-md-6 firstRowLeft"
  }, [_c('label', {
    attrs: {
      "for": "nameAndSurname"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.name")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.nameAndSurname,
      expression: "nameAndSurname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "id": "nameAndSurname",
      "placeholder": _vm.$t('complaint form.placeholder.name')
    },
    domProps: {
      "value": _vm.nameAndSurname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.nameAndSurname = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-12 col-md-6 firstRowRight"
  }, [_c('label', {
    attrs: {
      "for": "tradingAccountNumber"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.trading-account")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.tradingAccountNumber,
      expression: "tradingAccountNumber"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "id": "tradingAccountNumber"
    },
    domProps: {
      "value": _vm.tradingAccountNumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.tradingAccountNumber = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.email")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "input",
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": _vm.$t('complaint form.placeholder.email')
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {}, [_vm._v(_vm._s(_vm.$t("complaint form.mobile")) + "*")]), _c('div', [_c('MazPhoneNumberInput', {
    staticClass: "mb-32px",
    attrs: {
      "type": "tel"
    },
    on: {
      "update": function ($event) {
        _vm.results = $event;
      }
    },
    model: {
      value: _vm.phoneNumber,
      callback: function ($$v) {
        _vm.phoneNumber = $$v;
      },
      expression: "phoneNumber"
    }
  })], 1)]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.address")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.address,
      expression: "address"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "id": "address",
      "placeholder": _vm.$t('complaint form.placeholder.address')
    },
    domProps: {
      "value": _vm.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.address = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-12 col-md-6 firstRowLeft"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.city")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.city,
      expression: "city"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "id": "city",
      "placeholder": _vm.$t('complaint form.placeholder.city')
    },
    domProps: {
      "value": _vm.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.city = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-12 col-md-6 firstRowRight"
  }, [_c('label', {
    attrs: {
      "for": "postalCode"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.postal code")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.postalCode,
      expression: "postalCode"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "id": "postalCode",
      "placeholder": _vm.$t('complaint form.placeholder.postal code')
    },
    domProps: {
      "value": _vm.postalCode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.postalCode = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.country")) + "*")]), _c('div', {
    staticClass: "select-wrapper"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.country,
      expression: "country"
    }],
    staticClass: "input",
    attrs: {
      "id": "country",
      "color": "primary"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.country = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.countries, function (country) {
    return _c('option', {
      key: country.value,
      domProps: {
        "value": country.value
      }
    }, [_vm._v(" " + _vm._s(country.label) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {}, [_c('label', {
    attrs: {
      "for": "subject"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.subject")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.subject,
      expression: "subject"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "id": "subject",
      "placeholder": _vm.$t('complaint form.placeholder.subject')
    },
    domProps: {
      "value": _vm.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.subject = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "form-group d-flex flex-column"
  }, [_c('label', {
    attrs: {
      "for": "descriptionOfComplaint"
    }
  }, [_vm._v(_vm._s(_vm.$t("complaint form.description")))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.descriptionOfComplaint,
      expression: "descriptionOfComplaint"
    }],
    staticClass: "input",
    staticStyle: {
      "height": "156px !important"
    },
    attrs: {
      "id": "descriptionOfComplaint",
      "cols": "30",
      "rows": "10"
    },
    domProps: {
      "value": _vm.descriptionOfComplaint
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.descriptionOfComplaint = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group",
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('label', {
    staticClass: "button-small text-grey-primary-main d-flex align-items-center",
    attrs: {
      "for": "attachment"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require("../../svg/upload.svg")
    }
  }), _c('span', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("complaint form.upload")) + " ")])]), _c('input', {
    attrs: {
      "type": "file",
      "id": "attachment"
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-center",
    staticStyle: {
      "margin": "38px auto 71px"
    }
  }, [_c('b-button', {
    staticClass: "round-btn-19-20",
    staticStyle: {
      "width": "200px !important"
    },
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_c('p', {
    staticClass: "button-large",
    staticStyle: {
      "height": "18px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("complaint form.send")) + " ")])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }